import React from "react";
import chroma from "chroma-js";
import Select, { StylesConfig } from "react-select";
import "./index.scss";
import updateCompoPrepared from "../../services/put-save-compo-prepared";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const customStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "6px",
    border: "1px solid #000",
    boxShadow: "none",
    fontSize: "16px",
    width: "50vw",
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    height: "auto",
    maxHeight: 60,
  }),
  multiValue: (styles) => ({
    ...styles,
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = "#159B7B";
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? color
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color
          : undefined,
      },
    };
  },
  multiValueLabel: (styles, { data }: any) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }: any) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "6px",
    border: "1px solid #dcdcdc",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }),
};

const formatOptionLabel = ({ label }: any) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <span>{label}</span>
  </div>
);

const CustomSelect = ({
  setListIngs,
  listIngrediants,
  listIngs,
  doGetPreparedById,
}: any) => {
  const handleChange = (selectedOptions: any) => {
    // Ensure no duplicate ingredients are added to listIngs
    const uniqueSelections = selectedOptions.filter(
      (option: any) => !listIngs.some((ing: any) => ing.label === option.label)
    );

    setListIngs([...listIngs, ...uniqueSelections]); // Set the selected options
  };

  // Filter out ingredients that are already selected
  const filteredIngredients = listIngrediants.filter(
    (ingredient: any) =>
      !listIngs.some((ing: any) => ing.label === ingredient.label)
  );
  const { preId } = useParams();

  const handleSaveCompo = () => {
    const bodyData = {
      ingredients: listIngs.map((ing: any) => ({
        product: ing?.id,
        quantity: ing?.quantity,
        portion: ing?.dose,
        unit: ing?.unit,
      })),
    };
    updateCompoPrepared(bodyData, preId)
      .then((response) => {
        if (response.data.status === 500) {
          toast.error("Internal server error!");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success(response.data.message);
          doGetPreparedById();
          return;
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "60vw",
        margin: "0 auto",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={filteredIngredients}
        getOptionLabel={(option: any) => option?.label}
        formatOptionLabel={formatOptionLabel}
        placeholder="Search or select a product..."
        styles={customStyles}
        onChange={handleChange}
      />
      <button className="add-button" onClick={() => handleSaveCompo()}>
        Ajouter
      </button>
    </div>
  );
};

export default CustomSelect;
