import React, { useEffect, useState } from "react";
import Layer from "../Layer";
import HeaderComponent from "../Header";
import { useLocation, useParams } from "react-router-dom";
import ProductSelect from "../ProductSelect";
import ListIngrediants from "../ListIngrediants";
import getPreparedById from "../../services/get-prepared-by-id";
import { toast } from "react-toastify";
import getAllCategories from "../../services/get-all-categories";
import { Unit } from "../../utils";

function EditPreProdScreen() {
  const { state }: any = useLocation();
  const [listIngs, setListIngs] = useState<any>([]);
  const [prepared, setPrepared] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [listIngrediants, setListIngrediants] = useState([]);

  const { preId } = useParams();

  const doGetAllCategories = () => {
    setLoading(true);
    getAllCategories()
      .then((response: any) => {
        setLoading(false);

        if (response.data.success) {
          toast.success(response.data.message);

          // Assuming the response contains an array of ingredients
          const ingredients = response.data.content;

          // Map ingredients to the desired structure
          const productOptions = ingredients.map((ingredient: any) => ({
            value: ingredient.name, // Assuming 'name' holds the ingredient name
            label: ingredient.name, // Using 'name' as the label
            id: ingredient._id, // Assuming 'id' is available
            quantity: 1, // Assuming 'id' is available
            dose: ingredient?.portion ? ingredient?.portion : 0,
            unit: ingredient?.unit ? ingredient?.unit : Unit.KG,
          }));

          setListIngrediants(productOptions); // Set the mapped list to your state
        } else {
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
          if (response.data.status === 500) {
            toast.error("Internal server error!");
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const doGetPreparedById = () => {
    getPreparedById(preId)
      .then((response: any) => {
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success(response?.data?.message);

          setPrepared(response?.data?.content);
          const ingredients = response.data.content?.ingredients;

          // Map ingredients to the desired structure
          const productOptions = ingredients.map((ingredient: any) => ({
            value: ingredient?.product?.name, // Assuming 'name' holds the ingredient name
            label: ingredient?.product?.name, // Using 'name' as the label
            id: ingredient?.product?._id, // Assuming 'id' is available
            quantity: ingredient?.quantity,
            dose: ingredient?.portion ? ingredient?.portion : 0,
            unit: ingredient?.unit ? ingredient?.unit : Unit.KG,
          }));

          setListIngs(productOptions); // Se
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    doGetAllCategories();
    doGetPreparedById();
  }, []);

  return (
    <>
      <Layer title={`pré-produit`}>
        <HeaderComponent
          title={`pré-produit/${state?.name}`}
          setModalProvidersIsOpen={() => {}}
          setQuery={() => {}}
          doHandleSearchProvider={() => {}}
        />

        <ProductSelect
          listIngrediants={listIngrediants}
          setListIngs={setListIngs}
          listIngs={listIngs}
          doGetPreparedById={doGetPreparedById}
        />
        <div>
          <ListIngrediants listIngs={listIngs} setListIngs={setListIngs} doGetPreparedById={doGetPreparedById} />
        </div>
      </Layer>
    </>
  );
}

export default EditPreProdScreen;
