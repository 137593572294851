// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-minimal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 250px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.header-minimal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-minimal {
  font-size: 22px;
  color: #2ca58d;
  font-weight: bold;
  margin: 0;
}

.edit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.edit-icon {
  width: 20px;
  height: 20px;
}

.stock-minimal {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.quantity-section-minimal {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantity-label-minimal {
  font-size: 16px;
  color: black;
}

.quantity-value {
  font-size: 20px;
  color: black;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/IngCard/index.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,0CAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,aAAA;AACJ;;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,SAAA;AAAJ;;AAGE;EACE,6BAAA;EACA,YAAA;EACA,eAAA;AAAJ;;AAGE;EACE,WAAA;EACA,YAAA;AAAJ;;AAGE;EACE,eAAA;EACA,WAAA;EACA,eAAA;AAAJ;;AAGE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,YAAA;AAAJ;;AAGE;EACE,eAAA;EACA,YAAA;EACA,iBAAA;AAAJ","sourcesContent":[".card-minimal {\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n    width: 250px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 200px;\n\n  }\n  \n  .header-minimal {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .title-minimal {\n    font-size: 22px;\n    color: #2ca58d;\n    font-weight: bold;\n    margin: 0;\n  }\n  \n  .edit-button {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .edit-icon {\n    width: 20px;\n    height: 20px;\n  }\n  \n  .stock-minimal {\n    font-size: 14px;\n    color: #666;\n    margin-top: 5px;\n  }\n  \n  .quantity-section-minimal {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .quantity-label-minimal {\n    font-size: 16px;\n    color: black;\n  }\n  \n  .quantity-value {\n    font-size: 20px;\n    color: black;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
