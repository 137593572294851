// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-button {
  background-color: #008f68;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
}

.add-button:hover {
  background-color: #006f50;
}

.unit-select__control {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent items from stacking */
}

.unit-select__multi-value {
  display: inline-block; /* Make sure values are inline */
}

.unit-select__value-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow-x: auto; /* Enable horizontal scroll */
}`, "",{"version":3,"sources":["webpack://./src/components/ProductSelect/index.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEE;EACE,yBAAA;AACJ;;AAEE;EACE,gBAAA,EAAA,gCAAA;EACA,mBAAA,EAAA,gCAAA;AACJ;;AAEE;EACE,qBAAA,EAAA,gCAAA;AACJ;;AAEE;EACE,aAAA;EACA,iBAAA,EAAA,qBAAA;EACA,gBAAA,EAAA,6BAAA;AACJ","sourcesContent":[".add-button {\n    background-color: #008f68;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    border-radius: 8px;\n    cursor: pointer;\n    margin-left: 10px;\n  }\n  \n  .add-button:hover {\n    background-color: #006f50;\n  }\n  \n  .unit-select__control {\n    overflow-x: auto; /* Enable horizontal scrolling */\n    white-space: nowrap; /* Prevent items from stacking */\n  }\n  \n  .unit-select__multi-value {\n    display: inline-block; /* Make sure values are inline */\n  }\n  \n  .unit-select__value-container {\n    display: flex;\n    flex-wrap: nowrap; /* Prevent wrapping */\n    overflow-x: auto; /* Enable horizontal scroll */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
