import React, { useEffect } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "boxicons/css/boxicons.min.css";
import "react-toastify/dist/ReactToastify.css";

import ProductsScreen from "./components/Layouts/ProductsScreen";
import StoresScreen from "./components/Layouts/StoresScreen";
import HistoryScreen from "./components/Layouts/HistoryScreen";
import HomeScreen from "./components/Layouts/HomeScreen";
import StoreDetails from "./components/Layouts/StoreDetails";
import AlimentationScreen from "./components/Layouts/AlimentationScreen";
import NotificationScreen from "./components/Layouts/NotificationScreen";
import NotificationDetailsScreen from "./components/Layouts/NotificationDetailsScreen";
import ProductDetailsScreen from "./components/Layouts/ProductDetailsScreen";
import TransferScreen from "./components/Layouts/TransferScreen";
import { useSocket, useSocketConfig } from "./services/socket";
import ContextApp, { AppContext } from "./components/Context/AppContext";
import PurchaceOrderScreen from "./components/PurchaceOrderScreen";
import PurchaceDetails from "./components/PurchaceDetails";
import ProvidersScreen from "./components/ProvidersScreen";
import BasketScreen from "./components/BasketScreen";
import PurchaceServicesDetails from "./components/PurchaseServicesDetail";
import PreProd from "./components/PreProd";
import EditPreProdScreen from "./components/EditPreProdScreen";
import ProtectedIn from "./layout/ProtectedIn";
import LoginScreen from "./screens/login-screen/LoginScreen";
import ConfirmationScreen from "./screens/confirmation-screen/ConfirmationScreen";
import ProtectedOut from "./layout/ProtectedOut";
import { getFromLocalStorage, setLocalStorage } from "./utils/localstorage";
import { toast } from "react-toastify";

function App() {
  useSocketConfig({
    init: true,
  });

  useSocket({
    socket: useSocketConfig(),
  });

  function handleMessageFromWeb(event: any) {
    try {
      const { action, key, value } = event.data || "";

      const token = getFromLocalStorage("token") || "";

      if (
        !token &&
        action &&
        action === "SET_LOCAL_STORAGE" &&
        key &&
        key === "token"
      ) {
        setLocalStorage(key, value);
      }
    } catch (error) {
      toast.error("Something wrong in message");
    }
  }

  function handleEventFromMobile(message: any) {
    try {
      const { action, key, value } = message?.data || {};

      const token = getFromLocalStorage("token") || "";

      if (
        !token &&
        action &&
        action === "SET_LOCAL_STORAGE" &&
        key &&
        key === "token"
      ) {
        setLocalStorage(key, value);
        window.location.reload();
      }
    } catch (error) {
      toast.error("Something wrong in event");
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleMessageFromWeb);
    return () => {
      window.removeEventListener("message", handleMessageFromWeb);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener("message", handleEventFromMobile);

    return () => document.removeEventListener("message", handleEventFromMobile);
  }, []);

  return (
    <ContextApp>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <ProtectedIn>
                <LoginScreen />
              </ProtectedIn>
            }
          />
          <Route
            path="/confirmation"
            element={
              <ProtectedIn>
                <ConfirmationScreen />
              </ProtectedIn>
            }
          />

          <Route
            path="/"
            element={
              <ProtectedOut>
                <ProductsScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/stores"
            element={
              <ProtectedOut>
                <StoresScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/history"
            element={
              <ProtectedOut>
                <HistoryScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedOut>
                <HomeScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/store-details/:id"
            element={
              <ProtectedOut>
                <StoreDetails />
              </ProtectedOut>
            }
          />
          <Route
            path="/alimentation"
            element={
              <ProtectedOut>
                <AlimentationScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/purchaceOrder"
            element={
              <ProtectedOut>
                <PurchaceOrderScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/purchaceDetails/:id"
            element={
              <ProtectedOut>
                <PurchaceDetails />
              </ProtectedOut>
            }
          />
          <Route
            path="/purchaceServicesDetails/:id"
            element={
              <ProtectedOut>
                <PurchaceServicesDetails />
              </ProtectedOut>
            }
          />

          <Route
            path="/alimentation/:storeId"
            element={
              <ProtectedOut>
                <TransferScreen />
              </ProtectedOut>
            }
          />

          <Route
            path="/notifications"
            element={
              <ProtectedOut>
                <NotificationScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/notification-details/:id"
            element={
              <ProtectedOut>
                <NotificationDetailsScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/section-details/:categoryId"
            element={
              <ProtectedOut>
                <ProductDetailsScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/section-details/:storeId/:categoryId"
            element={
              <ProtectedOut>
                <ProductDetailsScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/providers"
            element={
              <ProtectedOut>
                <ProvidersScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/basket"
            element={
              <ProtectedOut>
                <BasketScreen />
              </ProtectedOut>
            }
          />
          <Route
            path="/pre-prod"
            element={
              <ProtectedOut>
                <PreProd />
              </ProtectedOut>
            }
          />
          <Route
            path="/pre-prod/:preId"
            element={
              <ProtectedOut>
                <EditPreProdScreen />
              </ProtectedOut>
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ContextApp>
  );
}

export default App;
