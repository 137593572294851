import React, { useEffect, useState } from "react";
import "./index.scss";
import AsyncSelect from "react-select/async";
import getAllProducts from "../../services/get-list-products";
import { toast } from "react-toastify";
import { useSelector, useDispatch, useStore } from "react-redux";
import {
  INCREASE_PRODUCT_QUANTITY_BASCKET,
  PIZZA_SLICE_ADD_PRODUCT_BASCKET,
  PIZZA_SLICE_ADD_SERVICE_BASCKET,
  PIZZA_SLICE_EDIT_SERVICE_BASCKET,
  REMOVE_PRODUCT_BASCKET,
  RESET_LIST_BASCKET,
} from "../../store/slices/reducers/bascketSlice";
import addPurchase from "../../services/Add-purchace";
import addPurchaseService from "../../services/post-purchase-service";
import ServiceList from "../ServicesList";
import ServiceForm from "../ServiceForm";
import getServicesByPagination from "../../services/get-services-by-pagination";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import Lottie from "react-lottie";

function ModalAddItems({ setModalIsOpen, doGetInitPurchaces = () => {} }: any) {
  const [listProducts, setListProducts] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState("products");
  const [serviceInput, setServiceInput] = useState("");
  const [displayForm, setDisplayForm] = useState(false);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [listServices, setListServices] = useState<any>([]);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const [serviceToEdit, setServiceToEdit] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch(); // Use the useDispatch hook
  const services = useSelector(
    (state: any) => state?.entities?.bascketSlice?.services
  );

  console.log("services ===>", services);

  const handleTabClick = (tab: any) => {
    setSelectedTab(tab);
  };

  const closeModal = () => {
    resetList();
    setModalIsOpen(false);
  };

  const addService = () => {
    if (serviceInput.trim()) {
      const service = { name: serviceInput.trim(), price: 0 };

      // Dispatch the service to Redux store
      try {
        dispatch({
          type: PIZZA_SLICE_ADD_SERVICE_BASCKET,
          payload: service,
        });
      } catch (error) {
        console.log("Redux error:", error);
      }

      // setServices([...services, service]);
    }
  };

  const servicesSelec = useSelector(
    (state: Storage) => state?.entities?.bascketSlice?.selectedServices
  );

  const submit = () => {
    if (selectedTab == "products") {
      doAddPurchace();
    } else {
      doAddPurchaseService();
    }
  };
  const doAddPurchaseService = () => {
    const idsArray = servicesSelec?.map((serv: any) => serv.id);
    setLoading(true);

    const bodyData = {
      services: idsArray,
      newServices: services.map((service: any) => ({
        name: service.name,
        description: service.description,
        price: service.price,
        benefactor: {
          name: service.name,
          email: service.email,
          phone: service.benefactor.phone,
        },
      })),
    };
    addPurchaseService(bodyData)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast?.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success("le bon de commande a été ajouté avec succès");
          doGetInitPurchaces();

          closeModal();
        }
      })
      .catch((error) => {
        setLoading(true);

        console.log("error", error);
      });
  };

  const doAddPurchace = () => {
    const bodyData = {
      products: selectedProducts.map((product: any) => ({
        id: product.id,
        quantity: product.quantity,
      })),
    };
    addPurchase(bodyData)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast?.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success("le bon de commande a été ajouté avec succès");
          doGetInitPurchaces();

          closeModal();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const selectedOptions = (product: any) => {
    let prod = { ...product };
    prod.quantity = 1;
    try {
      dispatch({
        type: PIZZA_SLICE_ADD_PRODUCT_BASCKET,
        payload: prod,
      });
    } catch (error) {
      console.log(" redux error", error);
    }
  };

  const resetList = () => {
    try {
      dispatch({
        type: RESET_LIST_BASCKET,
        payload: {},
      });
    } catch (error) {
      console.log(" redux error", error);
    }
  };

  const removeProduct = (prod: any) => {
    try {
      dispatch({
        type: REMOVE_PRODUCT_BASCKET,
        payload: { prod },
      });
    } catch (error) {
      console.log(" redux error", error);
    }
  };

  let selectedProducts = useSelector(
    (state: any) => state?.entities?.bascketSlice?.products
  );
  const handleSelectService = (service: any) => {
    // Move the selected service to selectedServices
    setSelectedServices([...selectedServices, service]);

    // Remove the selected service from listServices
    setListServices(listServices.filter((item: any) => item.id !== service.id));
  };

  // Handle deselection (optional: to move back to listServices)
  const handleDeselectService = (service: any) => {
    // Move the deselected service back to listServices
    setListServices([...listServices, service]);

    // Remove the service from selectedServices
    setSelectedServices(
      selectedServices.filter((item: any) => item.id !== service.id)
    );
  };

  const doGetServicesByPagination = (page: any, limit: any) => {
    getServicesByPagination(page, limit)
      .then((response) => {
        if (response?.data?.status == 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
        }
        if (response?.data?.success) {
          setListServices(response?.data?.content?.services);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const doGetListProducts = () => {
    getAllProducts()
      .then((response) => {
        if (response?.data?.status == 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
        }
        if (response?.data?.success) {
          setListProducts(response?.data?.content);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    doGetListProducts();
    doGetServicesByPagination(1, 20);
  }, []);

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    const filteredOptions = listProducts.filter(
      (product: any) =>
        product.name.toLowerCase().includes(inputValue.toLowerCase()) &&
        !selectedProducts?.some(
          (selectedProduct: any) => selectedProduct.id === product._id
        )
    );

    callback(
      filteredOptions.map((product: any) => ({
        label: `${product.name} : ${product.portion} ${product.unit}`,
        value: product._id,
      }))
    );
  };

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#e2e2e2" : "white",
      color: "black",
    }),
  };

  const getDefaultOptions = () => {
    return listProducts
      ?.filter(
        (product: any) =>
          !selectedProducts.some(
            (selectedProduct: any) => selectedProduct.id === product._id
          )
      )
      .map((product: any) => ({
        label: `${product.name} : ${product.portion} ${product.unit}`,
        value: `${product.name} : ${product.portion} ${product.unit}`,
        id: product._id,
      }));
  };

  const handleChange = (prod: any, quantity: any) => {
    try {
      dispatch({
        type: INCREASE_PRODUCT_QUANTITY_BASCKET,
        payload: { productId: prod?.id, quantity: Number(quantity) },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const submitForm = (values: any) => {
    const service = {
      description: values?.description,
      price: values?.price,
      name: values?.serviceName,

      benefactor: {
        name: values?.beneficiaryName,
        email: values?.email,
        phone: values?.phoneNumber,
      },
    };

    // Dispatch the service to Redux store
    if (typeof indexToEdit == "number") {
      try {
        dispatch({
          type: PIZZA_SLICE_EDIT_SERVICE_BASCKET,
          payload: { index: indexToEdit, updatedService: service },
        });
      } catch (error) {
        console.log("Redux error:", error);
      }
    } else {
      try {
        dispatch({
          type: PIZZA_SLICE_ADD_SERVICE_BASCKET,
          payload: service,
        });
      } catch (error) {
        console.log("Redux error:", error);
      }
    }
    setIndexToEdit(null);
    setDisplayForm(false);
  };
  const servicess = useSelector(
    (state: any) => state?.entities?.bascketSlice?.services
  );
  console.log("basket +++ ---", servicess);

  return (
    <div className="modal-add-items-container">
      <div className="high-header">
        <div className="modal-add-items-header">
          <span className="modal-add-items-title">Nouveau Bon de commande</span>

          <i
            className="bx bx-x"
            style={{ fontSize: 26 }}
            onClick={closeModal}
          ></i>
        </div>
        <span>Veuillez sélectionner le type d’achat :</span>
      </div>
      {loading ? (
        <div>
          <Lottie
            options={defaultOptionsLoading}
            height={"60%"}
            width={"30%"}
          />
        </div>
      ) : (
        <>
          <div className="btns-content-tab">
            <button
              className={`prod-btn-tab ${
                selectedTab === "products" ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick("products")}
            >
              Produits
              <i
                className="bx bx-circle"
                style={{
                  color: "#fff",
                  marginLeft: 10,
                  borderRadius: 500,
                  borderColor: "red",
                  borderWidth: 2,
                }}
              ></i>
            </button>
            <button
              className={`services-btn-tab ${
                selectedTab === "services" ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick("services")}
            >
              Services
              <i
                className="bx bx-circle"
                style={{ color: "#fff", marginLeft: 10 }}
              ></i>
            </button>
          </div>
          {selectedTab === "products" ? (
            <div className="modal-add-items-body">
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={getDefaultOptions().map((product: any) => ({
                  label: product?.label,
                  value: product?.value,
                  id: product?.id,
                }))}
                styles={customStyles}
                placeholder="selectionner un produit"
                onChange={selectedOptions}
              />

              <div className="modal-add-items-list-prods-container">
                {selectedProducts?.length > 0 ? (
                  selectedProducts?.map((item: any) => (
                    <div
                      className="modal-add-items-one-item-heigh-container-s"
                      key={item.id}
                    >
                      <input
                        type="number"
                        className="modal-add-items-input-number"
                        value={item?.quantity}
                        onChange={(e) => handleChange(item, e.target.value)}
                      />
                      <div className="modal-add-items-one-item-container-s">
                        <span>{item?.value}</span>
                        <i
                          className="bx bx-trash"
                          style={{
                            fontWeight: 500,
                            fontSize: 20,
                            marginRight: 5,
                          }}
                          onClick={() => removeProduct(item)}
                        ></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div
              className={
                displayForm
                  ? "modal-add-items-form-service-container"
                  : "modal-add-items-list-service-container"
              }
            >
              {displayForm ? (
                <ServiceForm
                  submitForm={submitForm}
                  setDisplayForm={setDisplayForm}
                  serviceToEdit={serviceToEdit}
                  setEditMode={setEditMode}
                  editMode={editMode}
                />
              ) : (
                <ServiceList
                  setDisplayForm={setDisplayForm}
                  selectedServices={selectedServices}
                  listServices={listServices}
                  handleSelectService={handleSelectService}
                  setServiceToEdit={setServiceToEdit}
                  setEditMode={setEditMode}
                  editMode={editMode}
                  setIndexToEdit={setIndexToEdit}
                />
              )}
            </div>
          )}
          {displayForm ? (
            <></>
          ) : (
            <div className="modal-add-items-footer">
              <button
                className="modal-add-items-btn-cancel"
                onClick={closeModal}
              >
                Annuler
              </button>
              <button
                className="modal-add-items-btn-confirm"
                onClick={() => submit()}
              >
                Confirmer
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ModalAddItems;
