import React from "react";

import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "boxicons/css/boxicons.min.css";
import "react-toastify/dist/ReactToastify.css";

import ProductsScreen from "./components/Layouts/ProductsScreen";
import StoresScreen from "./components/Layouts/StoresScreen";
import HistoryScreen from "./components/Layouts/HistoryScreen";
import HomeScreen from "./components/Layouts/HomeScreen";
import StoreDetails from "./components/Layouts/StoreDetails";
import AlimentationScreen from "./components/Layouts/AlimentationScreen";
import NotificationScreen from "./components/Layouts/NotificationScreen";
import NotificationDetailsScreen from "./components/Layouts/NotificationDetailsScreen";
import ProductDetailsScreen from "./components/Layouts/ProductDetailsScreen";
import TransferScreen from "./components/Layouts/TransferScreen";
import { useSocket, useSocketConfig } from "./services/socket";
import ContextApp, { AppContext } from "./components/Context/AppContext";
import PurchaceOrderScreen from "./components/PurchaceOrderScreen";
import PurchaceDetails from "./components/PurchaceDetails";
import ProvidersScreen from "./components/ProvidersScreen";
import BasketScreen from "./components/BasketScreen";
import PurchaceServicesDetails from "./components/PurchaseServicesDetail";
import PreProd from "./components/PreProd";
import EditPreProdScreen from "./components/EditPreProdScreen";

function App() {
  useSocketConfig({
    init: true,
  });

  useSocket({
    socket: useSocketConfig(),
  });

  return (
    <ContextApp>
      <Router>
        <Routes>
          <Route path="/" element={<ProductsScreen />} />
          <Route path="/stores" element={<StoresScreen />} />
          <Route path="/history" element={<HistoryScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/store-details/:id" element={<StoreDetails />} />
          <Route path="/alimentation" element={<AlimentationScreen />} />
          <Route path="/purchaceOrder" element={<PurchaceOrderScreen />} />
          <Route path="/purchaceDetails/:id" element={<PurchaceDetails />} />
          <Route path="/purchaceServicesDetails/:id" element={<PurchaceServicesDetails />} />


          <Route path="/alimentation/:storeId" element={<TransferScreen />} />

          <Route path="/notifications" element={<NotificationScreen />} />
          <Route
            path="/notification-details/:id"
            element={<NotificationDetailsScreen />}
          />
          <Route
            path="/section-details/:categoryId"
            element={<ProductDetailsScreen />}
          />
          <Route
            path="/section-details/:storeId/:categoryId"
            element={<ProductDetailsScreen />}
          />
          <Route path="/providers" element={<ProvidersScreen />} />
          <Route path="/basket" element={<BasketScreen />} />
          <Route path="/pre-prod" element={<PreProd />} />
          <Route path="/pre-prod/:preId" element={<EditPreProdScreen />} />



        </Routes>
      </Router>
    </ContextApp>
  );
}

export default App;
