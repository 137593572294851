import { useEffect, useState } from "react";
import HeaderComponent from "../../Header";
import SideBar from "../../SideBar";
import "./index.scss";
import { useParams } from "react-router-dom";
import getStockByStoreId from "../../../services/get-stock-by-store-id";
import { toast } from "react-toastify";
import StoreDetailsHeader from "../../StoreDetailsHeader";
import ListCard from "../../ListCard";
import getCategoriesByStore from "../../../services/get-categories-by-store";
import getStoreById from "../../../services/get-store-by-id";
import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../../assets/lottie-animations/animationOptions";
import Layer from "../../Layer";
import { useWindowDimensions } from "../../../utils";
import ListPreProd from "../../ListPreProd";
import getPreparedByStore from "../../../services/get-prepared-by-store";

const preProds = [{}, {}];

const StoreDetails = () => {
  const [stockData, setStockData] = useState<any>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [listCategoriesInStore, setListCategoriesInStore] = useState([]);
  const [storeDetails, setStoreDetails] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleContent, setToggleContent] = useState("products");
  const { id } = useParams();
  const [storeStockPrepared, setStoreStockPrepared] = useState<any>([]);
  const { width } = useWindowDimensions();

  const doGetCategoriesByStore = () => {
    setLoading(true);
    getCategoriesByStore(id)
      .then((response: any) => {
        setLoading(false);

        if (response.data.success) {
          setListCategoriesInStore(response.data.content);
        } else {
          if (response.data.status === 500) {
            toast.error("server error !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const doGetStoreById = () => {
    getStoreById(id)
      .then((response: any) => {
        if (response.data.success) {
          setStoreDetails(response.data.content[0]);
        } else {
          if (response.data.status === 500) {
            toast.error("server error !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    doGetCategoriesByStore();
    doGetStoreById();
    doGetPreparedByStore();
  }, []);

  const doGetStockByStore = () => {
    getStockByStoreId(id)
      .then((response: any) => {
        if (response?.data.status === 500) {
          toast.error("server error");
          return;
        }
        if (!response?.data.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data.success) {
          setStockData(response?.data?.content);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const doGetPreparedByStore = () => {
    getPreparedByStore(id)
      .then((response) => {
        if (response?.data.status === 500) {
          toast.error("server error");
          return;
        }
        if (!response?.data.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data.success) {
          setStoreStockPrepared(response?.data?.content);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error);
      });
  };
  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }
    doGetStockByStore();

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layer title={"stores"}>
      {loading ? (
        <div className="loading-wrapper">
          <div className="animation-wrapper">
            <Lottie
              options={defaultOptionsLoading}
              height={"60%"}
              width={"35%"}
            />
          </div>
        </div>
      ) : listCategoriesInStore.length > 0 ? (
        <div className="content-products-and-bar">
          <HeaderComponent
            title="stores"
            store={stockData[0]?.stock?.owner?.name}
          />
          <div className="store-details-products-container">
            <div>
              <StoreDetailsHeader
                storeDetails={storeDetails}
                setToggleContent={setToggleContent}
                toggleContent={toggleContent}
              />
            </div>
            <div>
              {toggleContent == "products" ? (
                <ListCard
                  listCategoriesInStore={listCategoriesInStore}
                  inStore={true}
                  storeId={id}
                  storeDetails={storeDetails}
                />
              ) : (
                <ListPreProd preProds={storeStockPrepared} inStore={true} doGetPreparedByStore={doGetPreparedByStore} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <HeaderComponent
            title="stores"
            store={stockData[0]?.stock?.owner?.name}
          />
          <div>
            <StoreDetailsHeader
              storeDetails={storeDetails}
              setToggleContent={setToggleContent}
              toggleContent={toggleContent}
            />
          </div>
          <div className="animation-wrapper">
            <Lottie
              options={defaultOptionsEmpty}
              height={"60%"}
              width={"30%"}
            />
          </div>
        </>
      )}
    </Layer>
  );
};
export default StoreDetails;
