import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./index.scss";
import addPrepared from "../../../services/Add-PreparedFood";
import { toast } from "react-toastify";
import { Unit } from "../../../utils";

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Produit est requis"),
  portion: Yup.number()
    .required("portion est requise")
    .positive("Doit être positif"),
  unit: Yup.string().required("Unité est requise"),
});

function ModalPreProd( {doGetPreProds} : any) {
  const doAddPreparedFood = (bodyData: any) => {
    addPrepared(bodyData)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doGetPreProds()
          toast.success("Pré-product added successfuly !");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="modal-pre-prod-container">
      <h3 className="modal-title">Nouveau Produits Pré-Préparés</h3>

      <Formik
        initialValues={{
          name: "",
          portion: "",
          unit: Unit.KG,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          // Handle form submission
          console.log(values);
          doAddPreparedFood(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="product-form">
            <div>
              <Field
                name="name"
                type="text"
                className="form-input"
                placeholder="Produits"
              />
              {touched.name && errors.name ? (
                <div className="error" style={{ color: "red", fontSize: 12 }}>
                  {errors.name}
                </div>
              ) : null}
            </div>

            <div className="dose-group">
              <Field
                name="portion"
                type="text"
                className="form-input"
                placeholder="portion"
              />

              <Field name="unit" as="select" className="unit-select">
                <option value={Unit.KG}>Kg</option>
                <option value={Unit.G}>g</option>
                <option value={Unit.L}>L</option>
              </Field>
              {touched.unit && errors.unit ? (
                <div className="error" style={{ color: "red" }}>
                  {errors.unit}
                </div>
              ) : null}
            </div>
            {touched.portion && errors.portion ? (
              <div className="error" style={{ color: "red", fontSize: 12 }}>
                {errors.portion}
              </div>
            ) : null}

            <div className="button-group">
              <button type="button" className="cancel-btn">
                Annuler
              </button>
              <button type="submit" className="submit-btn">
                Ajouter
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ModalPreProd;
