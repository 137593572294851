// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-pre-prod-container {
  height: 80vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default 4 columns for large screens */
  gap: 10px; /* Space between boxes */
  padding: 30px;
  overflow-y: auto;
}

.list-pre-prod-container::-webkit-scrollbar {
  display: none;
}

/* Breakpoints for responsive design */
@media (max-width: 1500px) {
  .list-pre-prod-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens (tablets in landscape mode) */
  }
}
@media (max-width: 1220px) {
  .list-pre-prod-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller tablets and large phones */
  }
}
@media (max-width: 600px) {
  .list-pre-prod-container {
    grid-template-columns: repeat(1, 1fr); /* 1 column for phones and very small screens */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 60vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ListPreProd/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,qCAAA,EAAA,wCAAA;EACA,SAAA,EAAA,wBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA,sCAAA;AACA;EACE;IACE,qCAAA,EAAA,6DAAA;EACF;AACF;AAEA;EACE;IACE,qCAAA,EAAA,mDAAA;EAAF;AACF;AAGA;EACE;IACE,qCAAA,EAAA,+CAAA;IACA,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,gBAAA;EADF;AACF","sourcesContent":[".list-pre-prod-container {\n  height: 80vh;\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr); /* Default 4 columns for large screens */\n  gap: 10px; /* Space between boxes */\n  padding: 30px;\n  overflow-y: auto;\n}\n\n.list-pre-prod-container::-webkit-scrollbar {\n  display: none;\n}\n\n/* Breakpoints for responsive design */\n@media (max-width: 1500px) {\n  .list-pre-prod-container {\n    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens (tablets in landscape mode) */\n  }\n}\n\n@media (max-width: 1220px) {\n  .list-pre-prod-container {\n    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller tablets and large phones */\n  }\n}\n\n@media (max-width: 600px) {\n  .list-pre-prod-container {\n    grid-template-columns: repeat(1, 1fr); /* 1 column for phones and very small screens */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-height: 60vh;\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
