import React from "react";
import "./index.scss";
import BoxPreProd from "../BoxPreProd";
const listPreProds = [
  {
    name: "Pâte de pizza",
    quantity: 1,
    dose: 1,
    unit: "Kg",
    pm: 15,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Sauce tomate",
    quantity: 2,
    dose: 0.5,
    unit: "L",
    pm: 10,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Mozzarella",
    quantity: 3,
    dose: 0.8,
    unit: "Kg",
    pm: 12,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Basilic",
    quantity: 5,
    dose: 0.02,
    unit: "Kg",
    pm: 5,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Olives noires",
    quantity: 1,
    dose: 0.1,
    unit: "Kg",
    pm: 8,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Huile d'olive",
    quantity: 1,
    dose: 0.05,
    unit: "L",
    pm: 7,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Jambon",
    quantity: 2,
    dose: 0.3,
    unit: "Kg",
    pm: 9,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Champignons",
    quantity: 4,
    dose: 0.4,
    unit: "Kg",
    pm: 6,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Fromage râpé",
    quantity: 1,
    dose: 0.6,
    unit: "Kg",
    pm: 14,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
  {
    name: "Oignons",
    quantity: 3,
    dose: 0.25,
    unit: "Kg",
    pm: 4,
    composition: [
      { name: "Farine", dose: 500, unit: "gr", quantity: 1 },
      { name: "huile", dose: 100, unit: "ml", quantity: 1 },
      { name: "Levure", dose: 50, unit: "gr", quantity: 1 },
    ],
  },
];

function ListPreProd({ preProds , inStore , doGetPreparedByStore}: any) {
  return (
    <div className="list-pre-prod-container">
      {preProds?.length > 0 ? (
        preProds?.map((ppre : any, index : any) => (
          <BoxPreProd key={index} ppre={ppre} inStore={inStore} doGetPreparedByStore={doGetPreparedByStore} />
        ))
      ) : (
        <>
          <span>Pas de produit pré-preparé</span>
        </>
      )}
    </div>
  );
}

export default ListPreProd;
