import React, { useState } from "react";
import "./index.scss";
import deleteIngrediantFromPrepared from "../../services/delete-ingrediant-from-prepared";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field } from "formik";
import { Unit } from "../../utils";

const ItemCard = ({ ing, onUpdate, toggleClick , doGetPreparedById }: any) => {
  const [quantity, setQuantity] = useState(ing.quantity || 10);
  const [dose, setDose] = useState(ing.dose);
  const [unit, setUnit] = useState(ing.unit  || Unit.KG); // Initialize with current unit or default

  const { preId } = useParams();

  const handleQuantityChange = (value: any) => {
    const newQuantity = Math.max(0, quantity + value);
    setQuantity(newQuantity);

    // Call the callback function to update the parent component
    onUpdate({ ...ing, quantity: newQuantity });
  };

  const handleDoseChange = (value: any) => {
    const newDose = Math.max(0, dose + value);
    setDose(newDose);

    // Call the callback function to update the parent component
    onUpdate({ ...ing, dose: newDose });
  };

  const handleQuantityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value);
    const newQuantity = value >= 0 ? value : 0; // Ensure quantity is non-negative
    setQuantity(newQuantity);
    onUpdate({ ...ing, quantity: newQuantity }); // Update the quantity in the ing object
  };

  const handleDoseInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    // Parse the value as a float
    const parsedValue = parseFloat(value);

    // Check if it's a valid float and non-negative
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setDose(parsedValue);
      onUpdate({ ...ing, dose: parsedValue }); // Update the dose in the ing object
    } else {
      // Optionally, you can show an error message or keep the value unchanged
      console.log("Invalid dose input, must be a non-negative float.");
    }
  };
  const handleDeleteIngrediant = (ing: any) => {
    const bodyData = {
      ingredients: [ ing?.id ]
    };

    deleteIngrediantFromPrepared(preId, bodyData)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("Internal server error!");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doGetPreparedById()
          toast.success("Ingredient deleted successfully!");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newUnit = event.target.value;
    setUnit(newUnit);
    onUpdate({ ...ing, unit: newUnit });
  };

  return (
    <div className="ingredient-card">
      <div className="card-header">
        <h3 className="ingredient-name">{ing?.label}</h3>
        <div
          className="trash-container"
          onClick={() => handleDeleteIngrediant(ing)}
        >
          <i className="bx bx-trash" style={{ color: "#f55" }}></i>
        </div>
      </div>

      <div className="card-body">
        <div className="input-group">
          <label>Quantité</label>
          <div className="input-control">
            <button onClick={() => handleQuantityChange(-1)}>-</button>
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityInputChange}
            />
            <button onClick={() => handleQuantityChange(1)}>+</button>
          </div>
        </div>

        <div className="input-group">
          <label>Dose</label>
          <div className="input-control">
            <button onClick={() => handleDoseChange(-1)}>-</button>
            <input
              type="number"
              value={dose}
              onChange={handleDoseInputChange}
            />
            <button onClick={() => handleDoseChange(1)}>+</button>
          </div>
          <select
            id="unit-select"
            value={unit}
            onChange={handleUnitChange}
            className="unit-select"
          >
            <option value={Unit.KG}>Kg</option>
            <option value={Unit.G}>g</option>
            <option value={Unit.L}>L</option>
          </select>
        </div>
      </div>

      <div className="card-footer">
        <button className="cancel-btn-pd" onClick={() => toggleClick(ing)}>
          Cancel
        </button>
        <button
          className={
            !Boolean(ing?.dose) || !Boolean(ing?.quantity)
              ? "confirm-btn-pd"
              : "confirm-btn-active-pd"
          }
          disabled={!Boolean(ing?.dose) || !Boolean(ing?.quantity)}
          onClick={() => toggleClick(ing)}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ItemCard;
