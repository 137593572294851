import React from "react";
import "./index.scss";

const IngCard = ({ ing, editClick = () => {} }: any) => {
  const stock = 120;

  return (
    <div className="card-minimal">
      <div className="header-minimal">
        <h2 className="title-minimal">{ing?.label}</h2>
        <button className="edit-button" onClick={() => editClick(ing)}>
          <i className="bx bx-edit"></i>
        </button>
      </div>
      <span className="stock-minimal">Dose: {ing?.dose}</span>
      <div className="quantity-section-minimal">
        <label className="quantity-label-minimal">Quantité</label>
        <span className="quantity-value">{ing?.quantity}</span>
      </div>
    </div>
  );
};

export default IngCard;
