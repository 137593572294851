import React, { useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { sideBarContent } from "../../utils";
function Menu({ showMenu, setShowMenu, toggleMenu }: any) {
  const navigate = useNavigate();
  const handleNavigation = (element: any) => {
    navigate(`/${element?.route}`);
  };
  return (
    <div className={`menu-wrapper-ic`}>
      <div className={`menu-container ${showMenu ? "open" : ""}`}>
        <div className="logo-container-ic">
          <div className="close-container-ic">
            <i
              className="bx bx-x"
              style={{ fontSize: 26 }}
              onClick={toggleMenu}
            ></i>
          </div>
          <div className="img-container-ic">
            <img src="./../../assets/logoInventor.png" alt="appIcon" />
          </div>
        </div>
        <ul>
          {sideBarContent?.map((element: any, index: any) => {
            console.log("element", element);

            return (
              <li key={index} onClick={() => handleNavigation(element)}>
                {element?.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Menu;
