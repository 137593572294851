import { useNavigate } from "react-router-dom";
import "./index.scss";

const StoreDetailsHeader = ({
  storeDetails,
  setToggleContent,
  toggleContent,
}: any) => {
  const navigate = useNavigate();
  console.log("storeDetails", storeDetails);

  return (
    <div className="store-details-header-high-container">
      <div className="store-details-header-container">
        <div className="store-details-left-side">
          <div>
            <img src="./../../../assets/StoreIconBg.png" alt="StoreIconBg" />
          </div>
          <div className="store-header-details">
            <p className="store-details-store-name">
              {storeDetails?.owner?.name}{" "}
            </p>
            <p>{storeDetails?.owner?.adresses?.name}</p>
          </div>
        </div>
        <div className="store-details-right-side">
          <button
            className="store-details-btn-aliment"
            onClick={() =>
              navigate(`/alimentation/${storeDetails?.owner?._id}`)
            }
          >
            <img
              src="./../../assets/icon-alimenter.png"
              alt="alimentationIcon"
            />
            <p className="alimenter-txt">Alimenter</p>
          </button>
        </div>
      </div>
      <div className="toggle-container">
        <button
          className={`ing-button ${
            toggleContent === "products" ? "selected" : ""
          }`}
          onClick={() => setToggleContent("products")}
        >
          Ingredients
        </button>
        <button
          className={`pre-button ${
            toggleContent === "prepared" ? "selected" : ""
          }`}
          onClick={() => setToggleContent("prepared")}
        >
          Pré-preparés
        </button>
      </div>
    </div>
  );
};
export default StoreDetailsHeader;
