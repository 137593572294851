import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./index.scss";
import updateQuantityPreparedInStoreStock from "../../../services/update-quantity-prepared-in-store-stock";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ModalEditQuantityPrepared = ({
  closeModal,
  ppre,
  doGetPreparedByStore,
}: any) => {
  // Helper function to format today's date as dd/mm/yyyy
  const formatDateToString = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const { id } = useParams();
  console.log("id", id, ppre?.preparedFood?._id);

  const initialValues = {
    quantity: ppre?.quantity || 0,
    unit: ppre.unit || "Kg",
    expirationDate: formatDateToString(new Date()),
  };

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .min(0, "La quantité doit être d'au moins 1")
      .required("La quantité est requise"),
    expirationDate: Yup.string()
      .matches(
        /^([0-2][0-9]|(3)[0-1])\/([0]?[1-9]|1[0-2])\/((19|20)\d\d)$/,
        "La date d'expiration doit être au format jj/mm/aaaa"
      )
      .test(
        "is-future-date",
        "La date d'expiration doit être dans le futur",
        (value) => {
          if (!value) return false;
          const [day, month, year] = value.split("/").map(Number);
          const expirationDate = new Date(year, month - 1, day);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Définit au début de la journée
          return expirationDate > today;
        }
      )
      .required("La date d'expiration est requise"),
  });

  const doUpdateQuantityPreparedInStore = (bodyData: any) => {
    updateQuantityPreparedInStoreStock(bodyData, id, ppre?.preparedFood?._id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          closeModal();
          doGetPreparedByStore();
          toast.success("Prepared updated quantity successfuly !");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  const handleSubmit = (values: typeof initialValues) => {
    console.log("Form values:", values);

    // Split the expiration date in dd/mm/yyyy format and rearrange to yyyy-mm-dd
    const [day, month, year] = values.expirationDate.split("/");
    const formattedExpirationDate = `${year}-${month}-${day}`;

    const bodyData = {
      quantity: values.quantity,
      expirationDate: formattedExpirationDate,
    };

    doUpdateQuantityPreparedInStore(bodyData);
  };

  return (
    <div className="edit-pizza-dough-modal">
      <h2>
        Edit <strong>{ppre?.preparedFood?.name}</strong>
      </h2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="quantity-input">
              <label>Quantité</label>
              <div className="quantity-control">
                <button
                  type="button"
                  onClick={() =>
                    setFieldValue("quantity", Math.max(0, values.quantity - 1))
                  }
                >
                  -
                </button>
                <Field name="quantity" type="number" />

                <button
                  type="button"
                  onClick={() => setFieldValue("quantity", values.quantity + 1)}
                >
                  +
                </button>
                <Field as="select" name="unit">
                  <option value="Kg">Kg</option>
                  <option value="g">g</option>
                  <option value="lb">lb</option>
                </Field>
              </div>
              <ErrorMessage
                name="quantity"
                component="div"
                className="error-message"
              />
            </div>

            <div className="expiration-date">
              <label>Expiration date</label>
              <div className="date-picker">
                <Field
                  name="expirationDate"
                  type="text"
                  placeholder="dd/mm/yyyy"
                />
                <button type="button" className="calendar-button">
                  📅
                </button>
              </div>
            </div>
            <ErrorMessage
              name="expirationDate"
              component="div"
              className="error-message"
            />

            <div className="buttons">
              <button
                type="button"
                className="cancel-button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button type="submit" className="confirm-button">
                Confirmer
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ModalEditQuantityPrepared;
