import React, { useState } from "react";
import { useFormik } from "formik";
import "./index.scss";
import { toast } from "react-toastify";
import { ArticleStatus, ServiceStatus } from "../../utils";
import DeleteServiceFromPurchase from "../../services/delete-service-from-purchase";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import Lottie from "react-lottie";

function DeleteService({ product, orderId, doGetPurchaceById , closeModal }: any) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      reasons: [] as ArticleStatus[],
      explanation: "",
    },
    onSubmit: (values) => {
      doDeleteProductFromPurchase(values);
    },
  });

  console.log("prod" , product);
  
  const handleCheckboxChange = (option: ArticleStatus) => {
    if (formik.values.reasons.includes(option)) {
      formik.setFieldValue(
        "reasons",
        formik.values.reasons.filter((item) => item !== option)
      );
    } else {
      formik.setFieldValue("reasons", [...formik.values.reasons, option]);
    }
  };

  const doDeleteProductFromPurchase = (values: any) => {
    setLoading(true);
    console.log("Deleting product with values:", values);
    // Your delete logic here
    const body = {
      note: values?.explanation,
      deleteReason: values?.reasons[0],
    };
    DeleteServiceFromPurchase(orderId, product?.serviceId?._id, body)
      .then((response: any) => {
        console.log("response", response);

        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          closeModal()
          doGetPurchaceById();
          toast.success("Product deleted successfuly !");
        }
      })
      .catch((error: any) => {
        setLoading(false);

        toast.error(error);
      });
  };

  return (
    <>
      {loading ? (
        <Lottie options={defaultOptionsLoading} height={"60%"} width={"30%"} />
      ) : (
        <form onSubmit={formik.handleSubmit} className="delete-prd-prchase">
          <div className="delete-prd-prchase-header">
            Suppression de service
          </div>
          <div className="delete-prd-prchase-body">
            <label style={{ width: "100%" }}>
              Veuillez sélectionner une ou plusieurs options.
              <div className="dropdown-container">
                <select
                  className="dropdown-select"
                  aria-readonly
                  value={
                    formik.values.reasons.length > 0
                      ? formik.values.reasons.join(", ")
                      : "Select"
                  }
                >
                  <option>Select</option>
                </select>
                <div className="dropdown-options">
                  {Object.values(ServiceStatus).map((status: any) => (
                    <label key={status} className="dropdown-option">
                      <input
                        type="checkbox"
                        checked={formik.values.reasons.includes(status)}
                        onChange={() => handleCheckboxChange(status)}
                      />
                      {status}
                    </label>
                  ))}
                </div>
              </div>
            </label>
            <label>
              Si nécessaire, expliquer pourquoi ce service devrait être
              supprimé.
              <textarea
                name="explanation"
                placeholder="Taper ici"
                value={formik.values.explanation}
                onChange={formik.handleChange}
              ></textarea>
            </label>
          </div>
          <div className="delete-prd-prchase-footer">
            <button
              type="button"
              className="cancel-btn"
              onClick={() =>{ formik.resetForm() ; closeModal()}}
            >
              Annuler
            </button>
            <button disabled={loading} type="submit" className="delete-btn">
              Supprimer
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export default DeleteService;
