import React, { useEffect, useState } from "react";
import { getSocketInstance } from "../../services/socket";
import { AppContext, AppContextType } from "../Context/AppContext";
import HeaderComponent from "../Header";
import "./index.scss";
import Modal from "react-modal";
import ModalAddItems from "../ModalAddItems";
import getAllPurchaces from "../../services/get-list-purchace";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../assets/lottie-animations/animationOptions";
import updateOrderStatus from "../../services/update-purchace-status";
import Layer from "../Layer";
import ListOrders from "../ListOrders";
import searchPurchase from "../../services/post-search-purchase";
import ListOrdersServices from "../ListOrdersServices";
import getServicesByPagination from "../../services/get-services-by-pagination";
import getServicesByPaginationUp from "../../services/get-services";
import updateServiceStatus from "../../services/update-service-status";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    minHeight: "400px",
    maxHeight: "75vh",

    width: "40%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,
    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function PurchaceOrderScreen() {
  const [showMenu, setShowMenu] = useState(false);
  const [orderList, setOrderList] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [orderListServices, setOrderListServices] = useState<any>([]);
  const [displayPurchaseType, setDisplayPurchaseType] = useState("products");

  // State to manage which dropdown is open for each order
  const [optionsShown, setOptionsShown] = useState<{ [key: string]: boolean }>(
    {}
  );

  const doUpdateStatus = (data: any, id: any) => {
    updateOrderStatus(data, id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doGetInitPurchaces();
          setSkip(0);
          setLimit(10);
        }
      })
      .catch((error) => {
        toast?.error(error);
      });
  };
  const doUpdateServiceStatus = (data: any, id: any) => {
    updateServiceStatus(data, id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doGetInitPurchacesService();
          setSkip(0);
          setLimit(10);
        }
      })
      .catch((error) => {
        toast?.error(error);
      });
  };
  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const doGetAllPurchaces = (limit: any, skip: any) => {
    if (limit !== undefined && skip !== undefined) {
      setLoading(true);
      getAllPurchaces(limit, skip)
        .then((response) => {
          setLoading(false);

          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
          if (response?.data?.success) {
            setOrderList([
              ...orderList,
              ...response?.data?.content?.purchaseOrders,
            ]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const doGetAllPurchacesServices = (limit: any, skip: any) => {
    if (limit !== undefined && skip !== undefined) {
      setLoading(true);
      getServicesByPaginationUp(limit, skip)
        .then((response) => {
          setLoading(false);

          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
          if (response?.data?.success) {
            setOrderListServices([
              ...orderListServices,
              ...response?.data?.content?.services,
            ]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const doGetInitPurchaces = () => {
    setLoading(true);
    getAllPurchaces(10, 0)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setOrderList(response?.data?.content?.purchaseOrders);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const doGetInitPurchacesService = () => {
    setLoading(true);
    getServicesByPaginationUp(10, 0)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setOrderListServices(response?.data?.content?.services);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const loadMore = () => {
    let newSkip = skip + limit;
    doGetAllPurchaces(limit, newSkip);
    setSkip(newSkip);
  };

  useEffect(() => {
    doGetInitPurchaces();
    doGetAllPurchacesServices(10, 0);
  }, []);

  const toggleOptions = (orderId: string) => {
    setOptionsShown((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };
  const doHandleSearchPurchase = (query: any) => {
    const data = { query: query };
    searchPurchase(data)
      .then((response) => {
        if (response?.data?.success) {
          setOrderList(response?.data.content);
        } else {
          toast?.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    if (!Boolean(query)) {
      doGetInitPurchaces();
    }
  }, [query]);
  console.log(displayPurchaseType);

  return (
    <>
      <Layer title={"purchaceOrder"}>
        <HeaderComponent
          title="Bons de commande"
          setModalIsOpen={setModalIsOpen}
          setQuery={setQuery}
          doHandleSearchProvider={doHandleSearchPurchase}
          setDisplayPurchaseType={setDisplayPurchaseType}
          displayPurchaseType={displayPurchaseType}
        />
        {displayPurchaseType == "products" ? (
          <>
            {loading ? (
              <div className="loading-container">
                <Lottie
                  options={defaultOptionsLoading}
                  height={"20%"}
                  width={"12%"}
                />
              </div>
            ) : orderList?.length > 0 ? (
              <ListOrders
                loadMore={loadMore}
                orderList={orderList}
                doUpdateStatus={doUpdateStatus}
              />
            ) : (
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            )}
          </>
        ) : (
          <>
            {loading ? (
              <div className="loading-container">
                <Lottie
                  options={defaultOptionsLoading}
                  height={"20%"}
                  width={"12%"}
                />
              </div>
            ) : orderListServices?.length > 0 ? (
              <ListOrdersServices
                loadMore={loadMore}
                orderList={orderListServices}
                doUpdateStatus={doUpdateServiceStatus}
              />
            ) : (
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            )}
          </>
        )}
      </Layer>

      <Modal
        isOpen={modalIsOpen}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalAddItems
          setModalIsOpen={setModalIsOpen}
          doGetInitPurchaces={doGetInitPurchaces}
        />
      </Modal>
    </>
  );
}

export default PurchaceOrderScreen;
