// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  min-width: 310px;
  min-height: 110px;
  background-color: #ffffff;
  margin: 15px;
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(255, 241, 239, 0.1);
}

.card-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-categorie-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.average-coast-wrapper {
  display: flex;
  flex-direction: column;
}
.average-coast-wrapper .price-label {
  font-size: 10px;
  font-weight: 400;
  color: #848484;
}
.average-coast-wrapper .price-value {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.card-header-wrapper {
  display: flex;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/card/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,oDAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAGF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAGF;;AAAA;EACE,aAAA;EACA,sBAAA;AAGF;AAFE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAIJ;AAFE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAIJ;;AAAA;EACE,aAAA;EACA,mBAAA;AAGF","sourcesContent":[".card-container {\n  min-width: 310px;\n  min-height: 110px;\n  background-color: #ffffff;\n  margin: 15px;\n  padding: 10px;\n  border-radius: 6px;\n  background: #fff;\n  box-shadow: 0px 4px 4px 0px rgba(255, 241, 239, 0.1);\n}\n.card-details {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.card-header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.card-categorie-wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.average-coast-wrapper {\n  display: flex;\n  flex-direction: column;\n  .price-label {\n    font-size: 10px;\n    font-weight: 400;\n    color: #848484;\n  }\n  .price-value {\n    font-size: 14px;\n    font-weight: 700;\n    color: #000;\n  }\n}\n\n.card-header-wrapper {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
