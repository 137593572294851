import React from "react";
import "./index.scss";
import IngCard from "../IngCard";
import ItemCard from "../ItemCard";

function ListIngrediants({ listIngs, setListIngs , doGetPreparedById }: any) {
  console.log("list ings", listIngs);

  // Function to handle click on the edit button
  const editClick = (ing: any) => {
    const updatedList = listIngs.map((item: any) => {
      if (item?.label === ing?.label) {
        return { ...item, isSelected: !item?.isSelected }; // Set isSelected to true
      }
      return item; // Return the item unchanged
    });
    setListIngs(updatedList); // Update the state with the modified list
    console.log("Updated Ingredients:", updatedList); // Log the updated list
  };

  // Function to handle updates from ItemCard
  const handleUpdateIng = (updatedIng: any) => {
    const updatedList = listIngs.map((item: any) => {
      if (item?.label === updatedIng?.label) {
        return updatedIng; // Update the ingredient that was modified
      }
      return item; // Return the item unchanged
    });
    setListIngs(updatedList); // Update the state with the modified list
  };

  return (
    <div className="cards-ings-wrapper">
      {listIngs?.length > 0 ? (
        listIngs?.map((ing: any, index: any) =>
          ing?.isSelected ? (
            <ItemCard ing={ing} onUpdate={handleUpdateIng} key={index} toggleClick={editClick} doGetPreparedById={doGetPreparedById} />
          ) : (
            <IngCard ing={ing} editClick={editClick} key={index}  />
          )
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default ListIngrediants;
