// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-ings-wrapper {
  height: 70vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default 4 columns for large screens */
  gap: 10px; /* Space between boxes */
  padding: 30px;
  overflow-y: auto;
}

.cards-ings-wrapper::-webkit-scrollbar {
  display: none;
}

/* Breakpoints for responsive design */
@media (max-width: 1500px) {
  .cards-ings-wrapper {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens (tablets in landscape mode) */
    overflow-y: auto;
  }
}
@media (max-width: 1220px) {
  .cards-ings-wrapper {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller tablets and large phones */
    overflow-y: auto;
    max-height: 50vh;
  }
}
@media (max-width: 600px) {
  .cards-ings-wrapper {
    grid-template-columns: repeat(1, 1fr); /* 1 column for phones and very small screens */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50vh;
    overflow-y: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ListIngrediants/index.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,qCAAA,EAAA,wCAAA;EACA,SAAA,EAAA,wBAAA;EACA,aAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,aAAA;AAAJ;;AAGE,sCAAA;AACA;EACE;IACE,qCAAA,EAAA,6DAAA;IACA,gBAAA;EAAJ;AACF;AAIE;EACE;IACE,qCAAA,EAAA,mDAAA;IACA,gBAAA;IACA,gBAAA;EAFJ;AACF;AAME;EACE;IACE,qCAAA,EAAA,+CAAA;IACA,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,gBAAA;IACA,gBAAA;EAJJ;AACF","sourcesContent":["\n.cards-ings-wrapper {\n    height: 70vh;\n    width: 100%;\n    display: grid;\n    grid-template-columns: repeat(4, 1fr); /* Default 4 columns for large screens */\n    gap: 10px; /* Space between boxes */\n    padding: 30px;\n    overflow-y: auto;\n  }\n  \n  .cards-ings-wrapper::-webkit-scrollbar {\n    display: none;\n  }\n  \n  /* Breakpoints for responsive design */\n  @media (max-width: 1500px) {\n    .cards-ings-wrapper {\n      grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens (tablets in landscape mode) */\n      overflow-y: auto;\n\n    }\n  }\n  \n  @media (max-width: 1220px) {\n    .cards-ings-wrapper {\n      grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller tablets and large phones */\n      overflow-y: auto;\n      max-height: 50vh;\n\n    }\n  }\n  \n  @media (max-width: 600px) {\n    .cards-ings-wrapper {\n      grid-template-columns: repeat(1, 1fr); /* 1 column for phones and very small screens */\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      max-height: 50vh;\n      overflow-y: auto;\n\n      \n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
