import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../utils";
import "./index.scss";
import { useState } from "react";
import { deleteFromLocalStorage } from "../../utils/localstorage";
import { LocalStorageEnum } from "../../utils/localstorage/LocalStorageEnum";
import Modal from "react-modal";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
    padding: "5vh",
    borderRadius: "10px",
    textAlign: "center",
    margin: "0rem 1px",
  },
};

const HeaderComponent = ({
  title = "",
  query = "",
  setQuery = () => {},
  setAddIsOpen = () => {},
  searchAction = () => {},
  setAddProductOpen = () => {},
  store,
  id = "",
  details,
  setModalIsOpen = () => {},
  setModalProvidersIsOpen = () => {},
  doHandleSearchProvider = () => {},
  storeDetails,
  setDisplayPurchaseType = () => {},
  displayPurchaseType,
}: any) => {
  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const handleChangeQueryy = (e: any, setFieldValue: any) => {
    setFieldValue("query", e.target.value);
    setQuery(e.target.value);
  };
  const handleChangeQuery = (e: any) => {
    e.preventDefault();

    setQuery(e.target.value);
  };
  const handleSearchSubmit = (values: any) => {
    setQuery(values.query);

    doHandleSearchProvider(values.query);

    // searchAction();
  };
  let basketOrderProducts = useSelector(
    (state: any) => state?.entities?.bascketSliceOrder?.products
  );

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const handleDisconnect = () => {
    deleteFromLocalStorage(LocalStorageEnum.TOKEN);
    deleteFromLocalStorage(LocalStorageEnum.PHONENUMBER);
    navigate(`/login`);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
        // @ts-ignore
        style={customModalStyles}
        shouldCloseOnEsc
        preventScroll
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#000000",
          }}
        >
          Voulez vous vraiment se déconnecter ?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "50px",
            marginBottom: "20px",
            gap: "20px",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "700",
            color: "#ffffff",
            borderRadius: "5px",
            padding: "10px 20px",
            border: "none",
            outline: "none",
          }}
        >
          <button
            style={{
              outline: "none",
              background: "#999999",
              color: "white",
              border: "none",
              padding: "1.5vh 1.5vh",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "600",
            }}
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
            style={{
              outline: "none",
              background: "#AD9E89",
              color: "#000000",
              border: "none",
              padding: "1.5vh 1.5vh",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "600",
            }}
            onClick={() => handleDisconnect()}
          >
            Confirmer
          </button>
        </div>
      </Modal>

      <h2>{storeDetails?.owner?.name}</h2>

      <div className="header-container">
        <div className="right-container">
          {Boolean(store) ? (
            <p className="products-txt">
              {title}/{store}
            </p>
          ) : (
            <div>
              {Boolean(id) ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img src="./../../assets/greenStore.png" alt="greenStore" />
                  <p style={{ marginLeft: 25 }} className="products-txt">
                    {title}
                  </p>
                </div>
              ) : title === "Catégories" ? (
                <div className="prd-container">
                  <p className="products-txt">{title}</p>
                  <button
                    className="add-btn-prd"
                    onClick={() => setAddIsOpen(true)}
                  >
                    Ajouter
                  </button>
                </div>
              ) : title === "Bons de commande" ? (
                <div className="prd-container">
                  <p className="products-txt">{title}</p>
                  <button
                    className="add-btn-prd-order"
                    onClick={() => setModalIsOpen(true)}
                  >
                    Nouveau
                  </button>
                </div>
              ) : title === "Fournisseurs" ? (
                <div className="prd-container">
                  <p className="products-txt">{title}</p>
                  <button
                    className="add-btn-prd-order"
                    onClick={() => setModalProvidersIsOpen(true)}
                  >
                    Nouveau
                  </button>
                </div>
              ) : title === "Pré-produits" ? (
                <div className="prd-container">
                  <p className="products-txt">{title}</p>
                  <button
                    className="add-btn-prd-order"
                    onClick={() => setModalProvidersIsOpen(true)}
                  >
                    Nouveau
                  </button>
                </div>
              ) : (
                <div className="prod-btn-container">
                  <p className="products-txt">
                    {title === "Home" ? "Acceuil" : title}
                  </p>
                  {Boolean(details) && (
                    <button
                      className="add-btn-prd-prd"
                      onClick={() => setAddProductOpen(true)}
                    >
                      Ajouter
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {title === "Historique" ? (
          <div className="search-bar-container">
            <div
              style={{
                minWidth: 150,
                width: width > 768 ? "35vw" : "95%",
                paddingBottom: 15,
              }}
            >
              <div className="input-wrapper">
                <input
                  value={query}
                  placeholder="search"
                  className="input-search-style"
                  onChange={(e) => handleChangeQuery(e)}
                />
                <i
                  onClick={() => searchAction()}
                  className="bx bx-search"
                  style={{ fontSize: 28, marginRight: 10 }}
                ></i>
              </div>
            </div>
          </div>
        ) : title !== "Panier" ? (
          <div className="search-bar-container">
            <Formik initialValues={{ query: "" }} onSubmit={handleSearchSubmit}>
              {({ handleSubmit, setFieldValue }: any) => (
                <form onSubmit={handleSubmit}>
                  <div className="search-content-h">
                    <div className="input-wrapper">
                      <Field
                        name="query"
                        placeholder="search"
                        className="input-search-style"
                        onChange={(e: any) =>
                          handleChangeQueryy(e, setFieldValue)
                        }
                      />
                      <i
                        onClick={handleSubmit}
                        className="bx bx-search"
                        style={{ fontSize: 28, marginRight: 10 }}
                      ></i>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <></>
        )}
        <div className="left-container">
          <div className="img-container" onClick={() => navigate("/basket")}>
            <i className="bx bx-basket" style={{ fontSize: 28 }}></i>

            {basketOrderProducts?.length > 0 ? (
              <div className="notif-num-container">
                <p className="num-txt">{basketOrderProducts?.length}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            className="img-container"

            // onClick={() => navigate("/notifications")}
          >
            <img
              style={{ objectFit: "contain", maxWidth: 30, maxHeight: 30 }}
              src="./../../../../assets/notif.png"
              alt="Logo"
            />
            <div className="notif-num-container">
              <p className="num-txt">3</p>
            </div>
          </div>
          <div className="img-container" onClick={openModal}>
            <i className="bx bx-log-out" style={{ fontSize: 28 }}></i>
          </div>
        </div>
      </div>
      {title === "Bons de commande" && (
        <div className="toggle-container">
          <div>
            <button
              className={
                displayPurchaseType == "products"
                  ? "btn-prods-selected"
                  : "btn-prods"
              }
              onClick={() => setDisplayPurchaseType("products")}
            >
              products
            </button>
            <button
              className={
                displayPurchaseType == "services"
                  ? "btn-services-selected"
                  : "btn-services"
              }
              onClick={() => setDisplayPurchaseType("services")}
            >
              services
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default HeaderComponent;
