import { useEffect, useState } from "react";
import HeaderComponent from "../Header";

import Layer from "../Layer";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import ListPreProd from "../ListPreProd";
import Modal from "react-modal";
import ModalPreProd from "../modals/ModalPreProd";
import getPreProds from "../../services/get-pre-prods";
import { toast } from "react-toastify";
const customStylesEditQte = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "33%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const PreProd = () => {
  const [modalProvidersIsOpen, setModalProvidersIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalPreProdIsOpen, setModalPreProdIsOpen] = useState(false);
  const [preProds, setPreProds] = useState([]);
  const closeModal = () => {
    setModalPreProdIsOpen(false);
  };

  const doGetPreProds = () => {
    setLoading(true);
    getPreProds()
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          closeModal()
          setPreProds(response?.data?.content);
          toast.success("pré-prods getted successfuly !");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    doGetPreProds();
  }, []);

  return (
    <>
      <Layer title={"pre-prod"}>
        <HeaderComponent
          title="Pré-produits"
          setModalProvidersIsOpen={setModalPreProdIsOpen}
          setQuery={setQuery}
          doHandleSearchProvider={() => {}}
        />
        <div style={{ height: "100%", width: "100%" }}>
          {loading ? (
            <Lottie
              options={defaultOptionsLoading}
              height={"20%"}
              width={"13%"}
            />
          ) : (
            <div>
              <ListPreProd preProds={preProds} inStore={false}/>
            </div>
          )}
        </div>
        <Modal
          isOpen={modalPreProdIsOpen}
          onRequestClose={closeModal}
          style={customStylesEditQte}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <ModalPreProd doGetPreProds={doGetPreProds} />
        </Modal>
      </Layer>
    </>
  );
};

export default PreProd;
