import React, { useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ModalEditQuantityPrepared from "../modals/ModalEditQuantityPrepared";
const customStylesEditQte = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "33%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
function BoxPreProd({ ppre, inStore, doGetPreparedByStore }: any) {
  const navigate = useNavigate();
  console.log("pp", ppre);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const editPreProd = (ppren: any) => {
    navigate(`/pre-prod/${ppren?._id}`, { state: { name: ppren?.name } });
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const editQuantity = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <div className="ingredient-card-bpp">
        <div className="card-header-bpp">
          <span className="title" style={{ color: "#1abc9c" }}>
            {inStore ? ppre?.preparedFood.name : ppre?.name}
          </span>
          <div
            className="edit-icon"
            onClick={() => (inStore ? editQuantity() : editPreProd(ppre))}
          >
            &#9998;
          </div>
        </div>
        <div className="card-body-bpp">
          <div className="info">
            <div>Dose</div>
            <div className="highlight-bpp">
              {inStore ? ppre?.preparedFood.portion : ppre?.portion}{" "}
              {inStore ? ppre?.preparedFood.unit : ppre?.unit}
            </div>
          </div>
          <div className="info-bpp">
            <div>Quantity</div>
            <div className="highlight-bpp">{ppre?.quantity}</div>
          </div>
          <div className="info-bpp">
            <div>PM</div>
            <div className="highlight-bpp">
              {inStore
                ? ppre?.preparedFood?.cost?.toFixed(3)
                : ppre?.cost?.toFixed(3)}{" "}
              Dt
            </div>
          </div>
          <div className="ingredients-header-bpp">
            {ppre?.ingredients?.length} ingredients
          </div>
          {inStore ? (
            <div className="ingredients-bpp">
              {ppre?.preparedFood?.ingredients?.length > 0 ? (
                ppre?.preparedFood?.ingredients
                  ?.slice(0, 4)
                  .map((comp: any, index: any) => (
                    <div key={index} className="ingredient-bpp">
                      {comp?.product?.name}
                    </div>
                  ))
              ) : (
                <></>
              )}
              {ppre?.ingredients?.length > 4 ? (
                <div className="ingredient-bpp">...</div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="ingredients-bpp">
              {ppre?.ingredients?.length > 0 ? (
                ppre?.ingredients?.slice(0, 4).map((comp: any, index: any) => (
                  <div key={index} className="ingredient-bpp">
                    {comp?.product?.name}
                  </div>
                ))
              ) : (
                <></>
              )}
              {ppre?.ingredients?.length > 4 ? (
                <div className="ingredient-bpp">...</div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStylesEditQte}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <ModalEditQuantityPrepared
            closeModal={closeModal}
            ppre={ppre}
            doGetPreparedByStore={doGetPreparedByStore}
          />
        </Modal>
      </div>
    </>
  );
}

export default BoxPreProd;
