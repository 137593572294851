// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-details-products-container {
  overflow-y: auto;
  width: 70vw;
  height: 79vh;
}

.animation-wrapper {
  height: 100%;
  width: 100%;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.loading-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .store-details-products-container {
    overflow-y: auto;
    width: 100vw;
    height: 70vh;
  }
}
.store-details-products-container::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/StoreDetails/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE;IACE,gBAAA;IACA,YAAA;IACA,YAAA;EACF;AACF;AAEA;EACE,aAAA;AAAF","sourcesContent":[".store-details-products-container {\n  overflow-y: auto;\n  width: 70vw;\n  height: 79vh;\n}\n\n.animation-wrapper {\n  height: 100%;\n  width: 100%;\n  padding: 20px;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}\n\n.loading-wrapper {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (max-width: 768px) {\n  .store-details-products-container {\n    overflow-y: auto;\n    width: 100vw;\n    height: 70vh;\n    \n  }\n}\n.store-details-products-container::-webkit-scrollbar{\n  display: none;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
